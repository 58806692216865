import { ModuleWithProviders, NgModule } from '@angular/core';

import { CORE_CONFIGURATION, CORE_CONFIGURATION_OPTIONS, ICoreModuleConfig } from './coreModuleConfig';
import { Config } from './utils/config';

export function CoreConfigFactory(config: ICoreModuleConfig) {
    if (config && config.clientConfigKey) {
        Config.setConfigKey(config.clientConfigKey);
    }
    return config;
}

@NgModule({})
export class CoreModule {
    static forRoot(coreConfig?: ICoreModuleConfig): ModuleWithProviders {
        return {
            ngModule: CoreModule,
            providers: [
                {
                    provide: CORE_CONFIGURATION_OPTIONS,
                    useValue: coreConfig || {}
                },
                {
                    provide: CORE_CONFIGURATION,
                    useFactory: CoreConfigFactory,
                    deps: [CORE_CONFIGURATION_OPTIONS]
                }
            ]
        };
    }
}
