import { BehaviorSubject } from 'rxjs';

import { EventEmitter } from '@angular/core';

export class EmitterService {
    private static _eventEmitters: {
        [eventName: string]: EventEmitter<any>;
    } = {};
    private static _valueChangedEventEmitters: {
        [eventName: string]: BehaviorSubject<any>;
    } = {};

    static getEvent(eventName: string): EventEmitter<any> {
        if (!this._eventEmitters[eventName]) {
            this._eventEmitters[eventName] = new EventEmitter();
        }
        return this._eventEmitters[eventName];
    }

    static getValueChangedEvent<T>(eventName: string): BehaviorSubject<T> {
        if (!this._valueChangedEventEmitters[eventName]) {
            this._valueChangedEventEmitters[eventName] = new BehaviorSubject<T>(
                undefined
            );
        }
        return this._valueChangedEventEmitters[eventName];
    }
}
